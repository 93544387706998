import { createApp } from 'vue';
import App from './App.vue';
import HeaderComponent from './components/NavbarComponent.vue';
import LoginPage from './views/LoginPage.vue';
import RegisterPage from './views/RegisterPage.vue';
import './assets/styles/styles.css';
import './assets/styles/main.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/tailwind.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import router from './router';
import store from './store';
import 'remixicon/fonts/remixicon.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueGoogleMaps from '@fawmi/vue-google-maps';
import Vue3Toastify from 'vue3-toastify';

// Add FontAwesome icons to the library
library.add(
  faChevronCircleLeft,
  faChevronCircleRight,
  faPlusCircle,
  faMinusCircle
);

const app = createApp(App).use(router);

app.use(store);
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBxOtesHhrJD_mMU5RQspOftg5qO4LzevA',
  },
});

app.use(Vue3Toastify, {
  autoClose: 3000,
})

// Register components
app.component(FontAwesomeIcon.name, FontAwesomeIcon);
app.component('HeaderComponent', HeaderComponent);
app.component('LoginPage', LoginPage);
app.component('RegisterPage', RegisterPage);

// Mount the app
app.mount('#app');