import { createStore } from 'vuex';

export default createStore({
    state: {
      books: [],
      bookDetails: [],
      pdfCache: {},
    },
    mutations: {
      setBooks(state, books) {
        state.books = books;
      },
      setBookDetails(state, bookDetails) {
        state.bookDetails = bookDetails;
      },
      setPdfCache(state, { path, data }) {
        state.pdfCache[path] = data;
      }
    },
    actions: {
      updateBooks({ commit }, books) {
        commit('setBooks', books);
      },
      updateBookDetails({ commit }, bookDetails) {
        commit('setBookDetails', bookDetails)
      },
      updatePdfCache({ commit }, { path, data }) {
        commit('setPdfCache', { path, data });
      },
      async fetchPdf({ commit, state }, path) {
        if (state.pdfCache[path]) {
          return state.pdfCache[path];
        } else {
          const url = `https://api.wisdomebooksclub.com/api/storage/${path}`;
          const loadingTask = pdfjsLib.getDocument(url);
          const pdf = await loadingTask.promise;
          const numPages = pdf.numPages;
  
          const pageData = [];
  
          for (let pageNum = 1; pageNum <= numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const viewport = page.getViewport({ scale: 1.5 });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
  
            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
  
            await page.render(renderContext).promise;
            pageData.push(canvas.toDataURL());
          }
  
          commit('setPdfCache', { path, data: pageData });
          return pageData;
        }
      }
    },
    getters: {
      getBooks: (state) => state.books,
      getBookDetails: (state) => state.bookDetails,
      getPdfCache: (state) => (path) => state.pdfCache[path]
    }
  });