<template>
  <div class="flex justify-center items-center">
    <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
.spinner-border {
  border-top-color: transparent;
  border-right-color: #3490dc;
  border-bottom-color: #3490dc;
  border-left-color: #3490dc;
}
</style>