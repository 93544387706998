<template>
  <NavbarComponent />
  <div class="min-h-screen bg-[#100F0D] text-white">
    <div class="container mx-auto px-4 py-8 mt-24">
      <div class="flex items-center mb-6">
        <button type="button" class="btn btn-secondary flex items-center" @click="goBack">
          <i class="fas fa-arrow-left mr-2"></i> Go Back
        </button>
        <h2 class="text-3xl font-bold ml-4">{{ title }}</h2>
      </div>
      <div class="row" v-if="books">
        <div v-for="book in books" :key="book.id" class="col-6 col-md-4 col-lg-3 mb-3">
          <div class="book-item">
            <div class="card modal-book-card">
              <img :src="book.book_cover ? storage + book.book_cover : defaultBook" alt="Book Cover"
                class="img-fluid rounded">
              <div class="overlay">
                <div class="overlay-content">
                  <h5 class="overlay-title text-white"><b>{{ book.book_title }}</b></h5>
                  <p class="overlay-author text-white">{{ book.author }}</p>
                  <button class="btn btn-primary" @click="openDetailModal(book)">More Details</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterComponent />
  <BookModal :book="selectedBook" :subscription="userSubscription" ref="bookModal" @close-all-modals="closeAllModals"/>
</template>

<script setup>
import NavbarComponent from '@/components/NavbarComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import BookModal from '@/components/eBooks/BookModal.vue';
import { defineProps, defineEmits, computed, ref } from 'vue'
import storage from '@/constants'
import defaultBook from '@/assets/images/coverless-book.jpg'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const props = defineProps({
    title: {
        type: String,
        require: true
    }
})

const user = JSON.parse(sessionStorage.getItem('user'));
const userSubscription = user ? user.subscription : null;
const store = useStore()
const books = computed(() => store.getters.getBooks)
const selectedBook = ref({})
const bookModal = ref(null)

const emit = defineEmits(['open-detail-modal', 'close-page'])

const openDetailModal = (book) => {
    selectedBook.value = book
    if(bookModal.value){
      bookModal.value.showModal()
    }
};

const closeAllModals = () => {
  const modals = document.querySelectorAll('.modal');
  modals.forEach(modal => {
    const modalInstance = bootstrap.Modal.getInstance(modal);
    modalInstance.hide();
  });
}

const router = useRouter()
const goBack = () => {
    router.go(-1)
};
</script>

<style scoped>
.book-item .card {
  position: relative;
  overflow: hidden;
  height: 25rem; 
}

.book-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.book-item:hover .overlay {
  opacity: 1;
}

.overlay-content {
  text-align: center;
}

.overlay-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.overlay-author {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.btn-secondary i {
  margin-right: 0.5rem;
}
</style>