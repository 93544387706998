<template>
  <div>
    <HeaderComponent />
    <div class="container-fluid pdf-container mt-4">
      <div v-if="selectedBook">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 text-center title">
            <h1>{{ selectedBook.title }}</h1>
            <p>{{ selectedBook.author }}</p>
          </div>
        </div>
        <!-- Flipbook -->
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <div class="flipbook_container" ref="flipbookContainer">

              <div v-if="loadingProgress < 100" class="flex flex-col items-center justify-center h-screen">
                <p class="text-white mb-4">Loading book...</p>
                <div class="w-1/2 bg-gray-200 rounded-full h-4 overflow-hidden">
                  <div class="bg-amber-500 h-4" :style="{ width: loadingProgress + '%' }"></div>
                </div>
                <span class="ml-2 text-white">{{ loadingProgress }}%</span>
              </div>
              <div v-else>
                <Flipbook ref="flipbookRef" class="flipbook" :pages="pages" v-slot="flipbook" :flipDuration="500"
                  :startPage="startPage" :dragToFlip="dragToFlip">
                  <div class="action-bar">
                    <button :class="{ disabled: !flipbook.canFlipLeft }" @click.stop="flipbook.flipLeft">
                      <i class="ri-arrow-left-s-line ri-2x"></i>
                    </button>
                    <button :class="{ disabled: !flipbook.canZoomOut }" @click.stop="flipbook.zoomOut">
                      <i class="ri-subtract-line ri-xl"></i>
                    </button>
                    <span class="page-num">
                      {{ flipbook.page }} of {{ flipbook.numPages }}
                    </span>
                    <button :class="{ disabled: !flipbook.canZoomIn }" @click.stop="flipbook.zoomIn">
                      <i class="ri-add-line ri-xl"></i>
                    </button>
                    <!-- <button :class="{ disabled: !flipbook.canFlipRight }" @click.stop="flipbook.flipRight">
                      <i class="fas fa-chevron-right"></i>
                    </button> -->
                    <button :class="{ disabled: isFlipRightDisabled }" @click.stop="handleFlipRight">
                      <i class="ri-arrow-right-s-line ri-2x"></i>
                    </button>
                    <input type="number" v-model="jumpPage" @keyup.enter="jumpToPage(flipbook)" min="1"
                      :max="flipbook.numPages" placeholder="Jump to page" />
                    <button @click.stop="toggleFullScreen">
                      <i class="ri-fullscreen-line ri-xl"></i>
                    </button>
                  </div>
                </Flipbook>

              </div>
            </div>
          </div>
        </div>

        <!-- Description Section -->
        <div class="row justify-content-center mt-4">
          <div class="col-12 col-md-10 text-center book-description ">
            <h2>Description</h2>
            <p>{{ selectedBook.description }}</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <!-- Star Rating -->
            <div class="star-rating text-center mb-3">
              <span v-for="star in 5" :key="star" class="star" @click="rate(star)">
                <i class="fa fa-star" :class="{ 'filled': star <= rating, 'empty': star > rating }"></i>
              </span>
              <div class="avg-rating">
                Avg rating: {{ avgRating.toFixed(1) }}/5 <i class="fa fa-star"></i>
              </div>
            </div>

            <!-- Comments List -->
            <div class="comments-list mt-4">
              <div v-for="(comment, index) in comments" :key="index" class="comment-item">
                <div class="comment-header">
                  <div class="user-info">
                    <img v-if="comment.user.avatar != null" :src="storage + comment.user.avatar" class="user-avatar">
                    <img v-else :src="defaultAvatar" class="user-avatar">
                    <strong class="user-name">{{ comment.user.first_name }} {{ comment.user.last_name }}</strong>
                  </div>
                  <div class="comment-date">{{ formatDate(comment.created_at) }}</div>
                </div>
                <div class="comment-body">
                  <p>{{ comment.comment }}</p>
                </div>
              </div>
            </div>

            <!-- Comment Input Section -->
            <div class="comment-box">
              <div class="user-info" v-if="user">
                <img v-if="userAvatar != null" :src="storage + userAvatar" class="user-avatar">
                <img v-else :src="defaultAvatar" class="user-avatar">
              </div>
              <div class="comment-input">
                <textarea v-model="comment" class="form-control" rows="3"
                  placeholder="Write your comment here..."></textarea>
                <div class="d-flex justify-content-end mt-2">
                  <button @click="submitComment" class="btn btn-primary comment-button">Add a comment...</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Flipbook from 'flipbook-vue';
import FooterComponent from "@/components/FooterComponent.vue";
import HeaderComponent from "@/components/NavbarComponent.vue";
import api from '@/api';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import storage from '@/constants';
import defaultAvatar from '@/assets/images/default-avatar.jpg';

const props = defineProps({
  id: Number,
});

const store = useStore(); 
const user = ref(null)
const userId = ref(null)
const route = useRoute();
const bookId = ref(Number(route.params.id) || 0);
const rating = ref(0);
const ratings = ref([]);
const comment = ref("");
const comments = ref([]);
const pages = ref([]);
const avgRating = ref(0);
//const selectedBook = JSON.parse(localStorage.getItem('bookDetails'));
const selectedBook = computed(() => store.getters.getBookDetails)
const jumpPage = ref(null)
const flipbookContainer = ref(null)
const flipbookRef = ref(null)
const userAvatar = ref(null)
const loadingProgress = ref(0)
const isSubscribed = ref(false)
const canFlipRight = ref(true)
const dragToFlip = ref(true)

const lastVisitedPageKey = computed(() => {
  return userId.value ? `lastVisitedPage_${userId.value}_${bookId.value}` : `lastVisitedPage_${bookId.value}`;
});

const startPage = ref(1);

const loadLastVisitedPage = () => {
  const lastVisitedPage = localStorage.getItem(lastVisitedPageKey.value);
  if (lastVisitedPage) {
    startPage.value = JSON.parse(lastVisitedPage);
  } else {
    startPage.value = 1;
  }
};

const getCommentsAndRatings = async () => {
  try {
    const response = await api.get(`/books/${bookId.value}/comments-and-ratings`);
    comments.value = response.data.comments;
    ratings.value = response.data.ratings;

    if (ratings.value.length > 0) {
      const totalRating = ratings.value.reduce((sum, rating) => sum + rating.rating, 0);
      avgRating.value = totalRating / ratings.value.length;
    } else {
      avgRating.value = 0;
    }

    const cachedUser = sessionStorage.getItem('user');
    let userId = null;
    if (cachedUser) {
      const userObject = JSON.parse(cachedUser);
      userId = userObject.id;
      const userRating = ratings.value.find(rating => rating.user_id === userId);
      if (userRating) {
        rating.value = userRating.rating;
      }
    }

  } catch (error) {
    console.error('Error fetching comments and ratings:', error);
  }
};


const isFlipRightDisabled = computed(() => {
  return !flipbookRef.value?.canFlipRight || (!isSubscribed.value && flipbookRef.value?.page >= 11);
});

const handleFlipRight = () => {
  if (isFlipRightDisabled.value) {
    return;
  }
  flipbookRef.value.flipRight();
};

// watch(startPage, (newPage) => {
//   if (!isSubscribed.value && flipbookRef.value.page == 10 || newPage > 10) {
//     dragToFlip.value = false;
//     canFlipRight.value = false;
//     startPage.value = 10;
//   } else {

//     dragToFlip.value = true;
//   }
// });

watch([userId, bookId], loadLastVisitedPage);

watch(
  () => flipbookRef.value?.page,
  (newPage) => {
    if (!isSubscribed.value && newPage >= 11) {
      dragToFlip.value = false;
      jumpPage.value = 10;
    } else {
      localStorage.setItem(lastVisitedPageKey.value, JSON.stringify(newPage));
      startPage.value = newPage;
      dragToFlip.value = true;
    }
  }
);

const jumpToPage = (flipbook) => {
  if (jumpPage.value >= 1 && jumpPage.value <= flipbook.numPages) {
    if (!isSubscribed.value && jumpPage.value > 11) {
      alert(`You need to be subscribed to access page ${jumpPage.value}.`);
      jumpPage.value = null;
    } else {
      startPage.value = jumpPage.value;
      jumpPage.value = null;
    }
  } else {
    alert('Invalid page number');
  }
};

const rate = async (star) => {
  rating.value = star;
  try {
    await api.post(`/books/${bookId.value}/rate`, { rating: star });
    getCommentsAndRatings();
  } catch (error) {
    console.error('Error submitting rating:', error);
  }
};

const submitComment = async () => {
  try {
    await api.post(`/books/${bookId.value}/comment`, { comment: comment.value });
    comment.value = '';
    getCommentsAndRatings()
  } catch (error) {
    console.error('Error submitting comment:', error);
  }
};

const findUserRating = (userId) => {
  const userRating = ratings.value.find(rating => rating.user_id === userId);
  return userRating ? userRating.rating : null;
};

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-US', options);
};

const loadPdf = async (path) => {
  const cachedData = store.getters.getPdfCache(path);

  if (cachedData) {
    pages.value = cachedData;
    loadingProgress.value = 100;
    return;
  }

  try {
    const url = `https://api.wisdomebooksclub.com/api/storage/${path}`;
    const loadingTask = pdfjsLib.getDocument(url);
    const pdf = await loadingTask.promise;
    const numPages = pdf.numPages;

    const pageData = [];

    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;
      pageData.push(canvas.toDataURL());

      loadingProgress.value = Math.round((pageNum / numPages) * 100);
    }

    pages.value = pageData;
    store.dispatch('updatePdfCache', { path, data: pageData });
  } catch (error) {
    console.error('Error loading PDF:', error);
  }
};

const toggleFullScreen = () => {
  if (!document.fullscreenElement) {
    flipbookContainer.value.requestFullscreen().catch(err => {
      alert('Error attempting to enable full-screen mode: ' + err.message + err.name)
    })
  } else {
    document.exitFullscreen();
  }
}

const handleKeyDown = (event) => {
  const flipbook = flipbookRef.value
  if (!flipbook) return;

  const target = event.target;
  const isInputField = target.tagName === 'INPUT' || target.tagName === 'TEXTAREA' || target.isContentEditable;

  if (isInputField) {
    return;
  }

  if (event.key === 'ArrowLeft') {
    flipbook.flipLeft();
  } else if (event.key === 'ArrowRight') {
    if (flipbookRef.value.page >= 11) {
      alert('You need to be subscribed to access page 12 onwards');
    } else {
      flipbook.flipRight();
    }
  } else if (event.key === 'f' || event.key === 'F') {
    toggleFullScreen();
  }
}

onMounted(async () => {
  window.scrollTo(0, 0);

  //this is for link for the production storage
  loadPdf(`books/pdf/${bookId.value}.pdf`);

  loadLastVisitedPage();

  const storedUser = JSON.parse(sessionStorage.getItem('user'));
  if (storedUser) {
    getCommentsAndRatings();
    user.value = storedUser;
    userId.value = storedUser.id;
    isSubscribed.value = storedUser.subscription === 'Gold' || storedUser.subscription === 'Platinum';
    if (storedUser.avatar) {
      userAvatar.value = storedUser.avatar;
    }
  }


  await nextTick();
  window.addEventListener('keydown', handleKeyDown)
});

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeyDown)
})
</script>

<style scoped>
/* Flipbook Styling */
.flipbook_container {
  width: 100%;
  height: 100%;
  margin: 1rem;
  position: relative;
}

.flipbook_container:fullscreen {
  width: 100vw;
  height: 100vh;
  margin: 0;
}

.flipbook {
  width: 100%;
  height: 90vh;
}

.flipbook:fullscreen {
  width: 100%;
  height: 100%;
}

.action-bar {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  display: flex;
  bottom: 0;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.action-bar button {
  margin: 5px 0;
  background-color: #F1C503;
  border: #F1C503;
  color: #F1C503;
  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
}

.action-bar button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-num {
  margin: 10px 0;
  color: #F1C503;
}

.action-bar input[type="number"] {
  margin: 5px 0;
  padding: 5px;
  width: 70px;
  border: 1px solid #F1C503;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  text-align: center;
}

.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}

.has-mouse .action-bar .btn:hover {
  color: #ccc;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}

.action-bar .btn:active {
  filter: none !important;
}

.action-bar .btn.disabled {
  color: #666;
  pointer-events: none;
}

.action-bar .page-num {
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
}

.title h1 {
  font-size: 30px;
  font-weight: 400;
  color: #f1c503;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.title p {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.book-description {
  background-color: #110f0d;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  margin-top: 10px;
}

.book-description h2 {
  color: #f1c503;
  margin-top: 20px;
  margin-bottom: 10px;
}

.book-description p {
  font-size: 16px;
  color: #d1d1d1;
}

/* Star Rating Styling */
.star-rating {
  font-size: 24px;
}

.star {
  cursor: pointer;
  color: #f1c503;
}

.star i {
  margin: 0 2px;
}

.star i.empty {
  color: #d1d1d1;
}

.star i.filled {
  color: #f1c503;
}


.user-rating {
  font-size: 0.875rem;
  color: whitesmoke;
}

.avg-rating {
  margin-top: 10px;
  font-size: 16px;
  color: whitesmoke;
}

.action-bar button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  margin: 0 5px;
  cursor: pointer;
}

.action-bar button.disabled {
  color: gray;
  cursor: not-allowed;
}

.action-bar .page-num {
  color: white;
  font-size: 1.2rem;
  margin: 0 10px;
}

/* User info Styling */
.user-info {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.user-avatar {
  margin-top: 1rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.user-name {
  font-size: 1.3rem;
}

/* Comments styling */
.comments-list {
  margin-top: 1rem;
  color: white;
}

.comment-item {
  border-bottom: 1px solid #ddd;
  /* padding: 1rem 0; */
}

.comment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}

.comment-body {
  margin-top: 0.5rem;
  color: white;
}

.comment-date {
  font-size: 0.9rem;
  color: #888;
}

.comment-box {
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
}

.comment-input {
  flex: 1;
}

.comment-box .comment-item {
  border: 1px solid #ddd;
  padding: 1rem;
  background-color: #000;
  border-radius: 5px;
  color: white;
}

.comment-box .comment-body {
  margin-top: 0.5rem;
}

.comment-box .form-control {
  flex: 1;
  border: 1px solid #ddd;
  background-color: #000;
  color: white;
  resize: none;
  padding: 0.5rem;
  border-radius: 5px;
}

.form-control::placeholder {
  color: #888;
}

.comment-button {
  font-size: 16px;
  color: #000;
  padding: 10px 20px;
  background-color: #f1c503;
  border: none;
  border-radius: 25px;
  cursor: pointer;
}

.click-to-flip {
  pointer-events: none;
  pointer-events: visible;
}
</style>
