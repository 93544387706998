import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginPage from '@/views/LoginPage.vue'
import RegisterPage from '@/views/RegisterPage.vue'
import eBooksPage from '@/views/eBooks/eBooksPage.vue'
import BookPdf from '@/views/eBooks/BookPdf.vue'
import CategoryPage from '@/views/eBooks/ViewAll.vue'
import ParallelPage from '@/views/ParallelPage.vue'

import KingJames from '@/views/KingJames.vue'
import CrossReference from '@/views/CrossReference.vue'
import WisdomBible from '@/views/WisdomBible.vue'
import BibleAtlas from '@/views/BibleAtlas.vue'
import AddInfoComponent from '@/components/eStudy/AddInfoComponent.vue'
import SubscriptionPage from '@/views/Stripe/SubscriptionPage.vue'
import SuccessPage from '@/views/Stripe/SuccessPage.vue'
import CancelPage from '@/views/Stripe/CancelPage.vue'
import ProfilePage from '@/views/ProfilePage.vue'

//vuex store
import store from '@/store/index.js'


const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: RegisterPage
  },
  {
    path: '/book-library',
    name: 'eBooksPage',
    component: eBooksPage,
  },

  {
    path: '/book-library/categories/:category',
    name: 'CategoryPage',
    component: CategoryPage,
    //props: route => ( { title: route.params.category, books: JSON.parse(route.query.books)})
    props: route => ( { title: route.params.category })
  },
  {
    path: '/book-library/read/:id-:title',
    name: 'BookPdf',
    component: BookPdf,
    props: true,
  },
  {
    path: '/parallel',
    name: 'ParallelPage',
    component: ParallelPage,
    meta: { subscriptionLevel: 'Platinum' }
  },
  {
    path: '/kingjames',
    name: 'KingJames',
    component: KingJames,
    meta: { subscriptionLevel: 'Platinum' }
  },
  {
    path: '/crossreference',
    name: 'CrossReference',
    component: CrossReference,
    meta: { subscriptionLevel: 'Platinum' }
  },
  {
    path: '/wisdombible',
    name: 'WisdomBible',
    component: WisdomBible,
    meta: { subscriptionLevel: 'Platinum' }
  },
  {
    path: '/bibleatlas',
    name: 'BibleAtlas',
    component: BibleAtlas,
    meta: { subscriptionLevel: 'Platinum' }
  },
  {
    path: '/addinfo',
    name: 'AddInfoComponent',
    component: AddInfoComponent,
    meta: { subscriptionLevel: 'Platinum' }
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: SubscriptionPage
  },
  {
    path: '/success',
    name: 'StripeSuccess',
    component: SuccessPage
  },
  {
    path: '/cancel',
    name: 'StripeCancel',
    component: CancelPage
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: ProfilePage
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const userSubscription = user ? user.subscription : null;

  if (user && (to.path === '/login' || to.path === '/register')) {
    next('/home');
    return;
  }

  if (to.meta.subscriptionLevel) {
    if (to.meta.subscriptionLevel === 'Gold' && userSubscription !== 'Gold' && userSubscription !== 'Platinum') {
      alert('You must be a Gold or Platinum Subscriber to access this page.');
      next('/subscription');
    } else if (to.meta.subscriptionLevel === 'Platinum' && userSubscription !== 'Platinum') {
      alert('You must be a Platinum Subscriber to access this page.');
      next('/subscription');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
